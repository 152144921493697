<template>
  <div class="bottomNav">
    <div class="bottomNav_bg">
      <div class="cooperative_brand">
        <span>合作品牌：</span>
        <a
          href="https://www.zhongxiao56.com/"
          target="_blank"
        >
          <img
            width="80px"
            style="transform: translateY(12px)"
            src="../assets/img/tllogo.png"
            alt=""
          />
        </a>
      </div>

      <div class="bottomMiddle">
        <div>
          <ul class="navItem">
            <li
              :style="{
                width: item.width,
                marginLeft: item.marginLeft,
                textAlign: 'left',
              }"
              v-for="(item, index) in bottomNav"
              :key="index"
            >
              <el-button
                style="
                  padding: 0;
                  color: rgba(255, 255, 255, 0.7);
                  font-size: 16px;
                  font-weight: 100;
                "
                type="text"
                @click="selectItem(item, index)"
              >{{ item.title }}
              </el-button>
            </li>
          </ul>
          <div
            style="color: 'rgba(255, 255, 255, 0.7)'"
            class="official"
          >
            官方客服：4008-700-400
          </div>
          <div
            style="margin-top: 5px; color: 'rgba(255, 255, 255, 0.7)'"
            class="address"
          >
            地址：上海市青浦区徐德路59号微格创意园A幢208室驮龙物流有限公司
          </div>
        </div>

        <img
          style="margin-top: 15px"
          width="191px"
          height="38px"
          src="../assets/img/zxBottom.png"
          alt=""
        />

        <div class="zx_wechat">
          <img
            width="76px"
            height="76px"
            src="../assets/img/zx_official_account.jpg"
            alt=""
          />
          <div>驮龙微信公众号</div>
        </div>
      </div>

      <div style="
          text-align: center;
          margin-top: 29px;
          color: 'rgba(255, 255, 255, 0.7)';
          font-weight: 100;
        ">
        powerd by 驮龙物流7.1.0 Copyright©2021 上海驮龙物流有限公司 All Rights
        Reserved
      </div>
      <div
        style="
          text-align: center;
          color: 'rgba(255, 255, 255, 0.7)';
          font-weight: 100;
        "
        class="website_filing"
      >
        <a
          href="http://beian.miit.gov.cn/"
          target="_blank"
        >网站备案/许可证号：沪ICP备2021023477号-1
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomNavigation",
  data() {
    return {
      bottomNav: [
        {
          title: "关于我们",
          marginRight: "84px",
          width: "140px",
          marginLeft: "7px",
          selectItem: 0,
          path: "/about-us"

        },
        {
          title: "主营业务",
          marginRight: "83px",
          width: "139px",
          selectItem: 1,
          path: "/main_business"
        },
        {
          title: "增值业务",
          marginRight: "84px",
          width: "140px",
          selectItem: 2,
          path: "/valueAdded-service"
        },
        {
          title: "服务支持",
          marginRight: "83px",
          width: "139px",
          selectItem: 3,
          path: "/service-support"
        },
        {
          title: "加入我们",
          marginRight: "52px",
          width: "108px",
          selectItem: 4,
          path: "/join_us"
        },
      ]
    }
  },
  methods: {
    selectItem(item, index) {
      if (item.path && this.$route.path !== item.path && item.selectItem == index) {
        this.$router.push(item.path)
      }
    }
  }

}
</script>

<style lang="less" scoped>
.bottomNav {
  width: 100%;
  height: 295px;
  background: #333333;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;
  .bottomNav_bg {
    width: 1200px;
    margin: 0 auto;
  }
  .cooperative_brand {
    height: 73px;
    padding-top: 18px;
    box-sizing: border-box;
    // opacity: 0.85;

    border-bottom: 1px solid #565656;
  }
  .navItem {
    display: flex;
    text-align: center;
    height: 74px;
    line-height: 74px;
  }
  .bottomMiddle {
    display: flex;
    font-weight: 100;
    .zx_wechat {
      flex: 1;
      //   width: 500px;
      text-align: right;
      margin-top: 15px;
      img {
        transform: translateX(-15px);
      }
    }
    .official::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../assets/img/phoneBottom.png);
      background-size: 14px 14px;
      transform: translateY(3px);
    }

    .address::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 17px;
      background: url(../assets/img/address.png);
      background-size: 14px 17px;
      transform: translateY(3px);
    }
  }
  .website_filing {
    a {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .website_filing::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 20px;
    background: url(../assets/img/website_filing.png);
    background-size: 18px 20px;
    transform: translateY(5px);
  }
}
</style>
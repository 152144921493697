var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomNav"},[_c('div',{staticClass:"bottomNav_bg"},[_vm._m(0),_c('div',{staticClass:"bottomMiddle"},[_c('div',[_c('ul',{staticClass:"navItem"},_vm._l((_vm.bottomNav),function(item,index){return _c('li',{key:index,style:({
              width: item.width,
              marginLeft: item.marginLeft,
              textAlign: 'left',
            })},[_c('el-button',{staticStyle:{"padding":"0","color":"rgba(255, 255, 255, 0.7)","font-size":"16px","font-weight":"100"},attrs:{"type":"text"},on:{"click":function($event){return _vm.selectItem(item, index)}}},[_vm._v(_vm._s(item.title)+" ")])],1)}),0),_c('div',{staticClass:"official",staticStyle:{"color":"'rgba(255, 255, 255, 0.7)'"}},[_vm._v(" 官方客服：4008-700-400 ")]),_c('div',{staticClass:"address",staticStyle:{"margin-top":"5px","color":"'rgba(255, 255, 255, 0.7)'"}},[_vm._v(" 地址：上海市青浦区徐德路59号微格创意园A幢208室驮龙物流有限公司 ")])]),_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"width":"191px","height":"38px","src":require("../assets/img/zxBottom.png"),"alt":""}}),_vm._m(1)]),_c('div',{staticStyle:{"text-align":"center","margin-top":"29px","color":"'rgba(255, 255, 255, 0.7)'","font-weight":"100"}},[_vm._v(" powerd by 驮龙物流7.1.0 Copyright©2021 上海驮龙物流有限公司 All Rights Reserved ")]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cooperative_brand"},[_c('span',[_vm._v("合作品牌：")]),_c('a',{attrs:{"href":"https://www.zhongxiao56.com/","target":"_blank"}},[_c('img',{staticStyle:{"transform":"translateY(12px)"},attrs:{"width":"80px","src":require("../assets/img/tllogo.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zx_wechat"},[_c('img',{attrs:{"width":"76px","height":"76px","src":require("../assets/img/zx_official_account.jpg"),"alt":""}}),_c('div',[_vm._v("驮龙微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"website_filing",staticStyle:{"text-align":"center","color":"'rgba(255, 255, 255, 0.7)'","font-weight":"100"}},[_c('a',{attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("网站备案/许可证号：沪ICP备2021023477号-1 ")])])
}]

export { render, staticRenderFns }
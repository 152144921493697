<template>
  <div class="headerNav">
    <!-- logo图片 -->
    <img
      class="headerLogo"
      src="../assets/img/logo.png"
      alt=""
    />
    <ul class="navItem">
      <li
        :style="{
          width: item.width,
          marginRight: item.marginRight,
        }"
        v-for="(item, index) in headerNav"
        :key="index"
        :class="[
          item.title == pathname ? 'selectItem' : '',
          item.index == selectHover ? 'selectHover' : '',
        ]"
        class="headeritem"
        @click="handelSelect(index, item, $event)"
        @mouseenter.self="handelMouseover(index)"
        @mouseleave.self="handelMouseout(index)"
      >
        <el-button type="text">{{ item.title }}
          <!-- 服务支持hover时切换图标 -->
          <!-- <img
            :class="selectHover == item.index ? 'drop_up_img' : 'drop_down_img'"
            width="8px"
            v-if="item.img"
            :src="img"
            alt=""
          /> -->
        </el-button>
        <!-- 服务支持二级菜单 -->
        <!-- <div v-if="item.mainBusiness">
          <div
            v-if="isShow"
            class="service_support"
            :style="{ background: item.background }"
          >
            <router-link :to="{ path: '/service-support' }">
              <div class="waybillQuery">
                <el-button
                  style="padding: 0"
                  type="text"
                >{{
                  item.mainBusiness
                }}</el-button>
              </div>
            </router-link>
            <router-link :to="{ path: '/autonomous_system' }">
              <div class="waybillQuery">
                <el-button
                  style="padding: 0"
                  type="text"
                >{{
                  item.autonomousSystem
                }}</el-button>
              </div>
            </router-link>
          </div>
        </div> -->
        <div v-if="item.mainBusiness">
          <div
            v-if="isShow"
            class="service_support"
            :style="{ background: item.background }"
          >
            <router-link :to="{ path: '/main_business' }">
              <div class="waybillQuery">
                <el-button
                  style="padding: 0"
                  type="text"
                >{{
                  item.mainBusiness
                }}</el-button>
              </div>
            </router-link>
            <router-link :to="{ path: '/valueAdded-service' }">
              <div class="waybillQuery">
                <el-button
                  style="padding: 0"
                  type="text"
                >{{
                  item.valueAdded
                }}</el-button>
              </div>
            </router-link>
          </div>
        </div>
      </li>
    </ul>
    <img
      class="phone"
      src="../assets/img/phone.png"
      alt=""
    />
    <div class="customerService">官方客服：4008-700-400</div>
  </div>
</template>

<script>
import "../style/init.css"
export default {
  name: "headerNav",
  props: {
    pathname: {
      type: String
    }
  },
  data() {
    return {
      selectItem: 0,
      selectHover: null,
      isShow: false,
      headerNav: [
        {
          title: "首页",
          width: "96px",
          marginRight: "45px",
          path: "/",
          index: 0

        },
        {
          title: "关于我们",
          width: "96px",
          marginRight: "41px",
          path: "/about-us",
          index: 1
        },
        {
          title: "产品介绍",
          width: "96px",
          marginRight: "41px",
          // path: "/main_business",
          mainBusiness: '主营业务',
          valueAdded: '增值服务',
          background: "rgba(158, 173, 201, 0.46)",
          img: true,
          index: 2
        },
        // {
        //   title: "产品介绍",
        //   width: "96px",
        //   marginRight: "41px",
        //   path: "/main_business",
        //   index: 2
        // },
        // {
        //   title: "增值服务",
        //   width: "96px",
        //   marginRight: "41px",
        //   path: "/valueAdded-service",
        //   index: 3
        // },
        {
          title: "服务支持",
          width: "96px",
          marginRight: "41px",
          // waybillQuery: "运单查询",
          // autonomousSystem: "自主系统",
          // background: "rgba(158, 173, 201, 0.46)",
          path: "/service-support",
          index: 3,
          img: true
        },
        {
          title: "加入我们",
          width: "96px",
          marginRight: "37px",
          path: "/join_us",
          index: 4,
          img:true,
        },
      ],
      img: require("../assets/img/down.png")
    }
  },
  methods: {
    handelSelect(index, item, event) {
      this.selectItem = index
      // if (index == 4 && this.$route.path !== '/service-support' && event.path[0].innerText == "服务支持") {
      //   this.$router.push('/service-support')
      // }
      if (item.path && this.$route.path !== item.path) {
        this.$router.push(item.path)
      }
    },
    handelMouseover(index) {
      this.selectHover = index
      if (index == 2) {
        this.isShow = true
        this.img = require("../assets/img/up.png")
      }

    },
    handelMouseout(index) {
      if (this.selectItem != 4) {
        this.selectHover = null
      }
      this.isShow = false
      this.img = require("../assets/img/down.png")
    }
  }
}
</script>

<style lang="less" scoped>
.headerNav {
  display: flex;
  justify-content: center;
  line-height: 80px;
  height: 80px;
  width: 1250px;
  margin: 0 auto;
  .navItem {
    display: flex;
    text-align: center;
  }
  .headerLogo {
    width: 166px;
    height: 31px;
    transform: translateY(20px);
    margin-right: 21px;
  }
  .selectItem {
    background: #734f21;
    .el-button.el-button--text {
      color: #ffffff !important;
      span {
        display: inline-block;
        width: 96px;
        height: 47px;
      }
    }
  }
  .selectHover {
    background: #734f21;
    .el-button.el-button--text {
      color: #ffffff !important;
      span {
        display: inline-block;
        width: 96px;
        height: 47px;
      }
    }
  }
  .phone {
    width: 16px;
    height: 17px;
    transform: translateY(33px);
    margin-right: 5px;
  }
  .headeritem {
    position: relative;
    .el-button.el-button--text {
      color: #000;
      font-size: 16px;
    }
    .service_support {
      position: absolute;
      z-index: 99999999999999999;
      line-height: 47px;
      width: 96px;
      font-size: 14px;
      .el-button.el-button--text {
        font-size: 14px;
        color: #fff;
      }
      .waybillQuery:hover {
        background: rgba(158, 173, 201, 0.8);
      }
    }
    .drop_down_img {
      transform: translateY(-2px);
    }
    .drop_up_img {
      transform: rotate(180deg) translateY(2px);
      //   transform: translateY(-2px);
    }
  }
}
</style>
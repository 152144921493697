<template>
  <div class="home">
    <headerNav pathname="首页" />
    <!-- 头部banner图 -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <img class="banner" :src="item.banner" alt />
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div> -->
      <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <!-- <div class="swiper-button-next"></div> -->
      <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>

    <!-- 右中间微信扫码关注查询 -->
    <div class="content_wechat">
      <div class="middle_wechat">
        <img
          width="123px"
          height="123px"
          src="../assets/img/zx_wx.png"
          alt=""
        />
        <div class="title">微信扫码关注查询</div>
      </div>

      <div class="tel_bg">
        <img src="../assets/img/tel.png" alt="" />
        <div class="tel_text">
          <div>官方电话：</div>
          <div>4008-700-400</div>
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div style="background: #f0f2f5; padding-top: 63px; box-sizing: border-box">
      <div class="aboutUs_bg">
        <img
          width="361px"
          height="55px"
          src="../assets/img/ABOUTUS.png"
          alt=""
        />
        <div class="aboutUs">
          关于我们
          <div class="Underline"></div>
        </div>
      </div>
      <div class="zx_introduce">
        <img src="../assets/img/contenimg.png" alt="" />
        <div class="information_bg">
          <div class="company_name">上海驮龙物流有限公司</div>
          <div class="company_Pinyin">
            Shanghai&nbsp;Tuolong&nbsp;Logistics&nbsp;Co.,&nbsp;Ltd
          </div>
          <div class="company_introduction">
            <div class="introduce introduction_information">
              上海驮龙物流有限公司成立于2020年1月，注册资金3000万人民币，总部设立于上海
            </div>
            <div class="introduction_information">
              青浦，公司致力于成为行业领先的公铁联运平台型物流企业。
            </div>
            <div class="introduction_information">
              公司通过强强联合、资本运作，整合了浙江东宇物流…
            </div>
          </div>

          <div class="see_more" @click="see_more">
            <el-button style="padding: 0; color: #fff" type="text"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="aboutUsList_bg">
        <div
          class="aboutUsList"
          :style="{
            width: item.width,
            paddingRight: item.paddingRight,
            paddingLeft: item.paddingRight,
            borderLeft: item.borderLeft,
          }"
          v-for="(item, index) in aboutUsList"
          :key="index"
        >
          <div
            class="aboutUs_title_bg"
            @mouseleave="handelLeave(index)"
            @mouseenter="handelenter(index)"
          >
            <img
              width="36px"
              height="36px"
              :src="aboutUsHover == item.index ? item.img_act : item.img"
              alt=""
            />
            <div :class="aboutUsHover == item.index ? 'aboutUs_title' : ''">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- 主营业务 -->
      <div class="Main_business_bag">
        <div class="Main_business_bg">
          <div>
            <img
              width="538px"
              height="56px"
              class="Main_business_title"
              src="../assets/img/Main_business.png"
              alt=""
            />
          </div>

          <!-- 轮播图文字 -->
          <div class="Main_business_swiper">
            <div
              class="Main_business_move"
              @click="$router.push('/main_business')"
            >
              <el-button style="padding: 0; color: #000000" type="text"
                >查看更多
                <img src="../assets/img/move.png" alt="" />
              </el-button>
            </div>
            <div class="scroll_bar" style="margin-top: 180px">
              <div class="scroll">
                <div class="scroll_circular" :style="{ top: swiper_circular }">
                  <div class="scroll_circular2">
                    <div class="scroll_circular3"></div>
                  </div>
                </div>
              </div>
              <ul>
                <li
                  :style="{ marginBottom: item.marginBottom }"
                  v-for="(item, index) in Main_business_name"
                  :key="index"
                  @click="handleActive(item.index)"
                >
                  <!-- {{ item.name }} -->
                  <el-button
                    :class="switchSwiper === item.index ? 'active' : ''"
                    type="text"
                    >{{ item.name }}</el-button
                  >
                </li>
              </ul>
            </div>
            <!-- 轮播图 -->
            <div class="main_business">
              <el-carousel
                indicator-position="outside"
                @change="handlechange"
                ref="nop"
              >
                <el-carousel-item
                  v-for="(item, index) in Main_businessList"
                  :key="index"
                >
                  <div class="main_business_banner">
                    <img width="881px" :src="item.banner" alt />
                    <div class="type_introduction_bg">
                      <ul class="type_introduction">
                        <li class="title">{{ item.title }}</li>
                        <li class="type_introduction_underline"></li>
                        <li class="">{{ item.content }}</li>
                      </ul>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 增值服务 -->
    <div class="increment_bg">
      <div class="aboutUs_bg">
        <img
          width="850px"
          height="56px"
          src="../assets/img/valueAddedServices.png"
          alt=""
        />
        <div class="aboutUs">
          增值服务
          <div class="Underline"></div>
        </div>
      </div>

      <div class="swiper-father">
        <div class="valueAddedServices">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in valueAddedServicesList"
              :key="index"
            >
              <div class="img_bg">
                <img
                  :src="item.banner"
                  width="280px"
                  height="260px"
                  @mouseover="bannerMouseover(index)"
                  @mouseout="bannerMouseout(index)"
                  :class="
                    staffStyleIndex === index ? 'picture_enlargement' : ''
                  "
                />
              </div>

              <div
                class="name"
                :class="staffStyleIndex === index ? 'staffStyle_name' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="toggle_arrow">
          <!-- Add Arrows -->
          <div class="swiper-button-next" slot="button-prev"></div>
          <div class="swiper-button-prev" slot="button-next"></div>
        </div>
      </div>

      <!-- <div class="increment_move">查看更多</div> -->

      <div
        style="margin-bottom: 88px"
        @click="$router.push('/valueAdded-service')"
      >
        <el-button style="padding: 0; color: #000000" type="text"
          >查看更多
          <img class="move" src="../assets/img/move.png" alt="" />
        </el-button>
      </div>
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
import Swiper from "swiper"; //引入swiper依赖
import "swiper/dist/css/swiper.min.css";
export default {
  data () {
    return {
      bannerList: [
        {
          banner: require("@/assets/img/banner_new.jpg"),
        },
        {
          banner: require("@/assets/img/banner.jpg"),
        },
        {
          banner: require("@/assets/img/quikBanner.jpg"),
        }
      ],
      Main_businessList: [
        {
          banner: require("@/assets/img/manin_business_g.jpg"),
          title: "精准高快G",
          content: "“精准高快G”是为了满足客户对货物高时效要求推出的产品，它以“高效、安全、专业、全方位跟踪、优质服务”作为服务基础，以“不负重托”为整个产品乃至整个公司的品牌代言。以此为基础，我司将打造成为物流企业中的佼佼者。"
        },
        {
          banner: require("@/assets/img/manin_business_t.jpg"),
          title: "精准特快T",
          content: "“精准特快T”是驮龙物流开业即推出的为企业及零散客户提供高端快运服务的标准快运产品，运输途中无中转，GPS全程定位，专人服务，安全快捷，主要城市之间的运输时间为2-3天，为客户提供经济实用的标准化运输服务，安全、准确、方便。",
        },
        {
          banner: require("@/assets/img/manin_business_k.jpg"),
          title: "特惠快运K",
          content: "“特惠快运K”作为我司推出的性价比最高的产品，采用标准定价与标准操作流程，为客户提供安全可靠、服务专业、高性价比的服务体验。安全经济的运输方式，让您发货放心，收货安心。优质运输产品，稳定的运力资源，定班次每日准时发车、准点到达，运行时效稳定，一般3-4天可达。",
        },
        {
          banner: require("@/assets/img/manin_business_p.jpg"),
          title: "普惠班列P",
          content: "“普惠班列P”作为我司推出的最经济实惠的产品，为客户提供优惠的价格、优质的服务和精准的时效，保障货物准时送达，具有价格与安全双重优势，让您花最少的钱，享受最优质的服务，大宗货物运输产品，价格经济实惠，标准操作，便捷服务，运行时效稳定，一般周内可达，安全系数较高。",
        },

      ],
      valueAddedServicesList: [
        {
          banner: require("@/assets/valueAddedService/insured_price_01.jpg"),
          name: "保价运输服务"
        },
        {
          banner: require("@/assets/valueAddedService/door_to_door_receiving_02.jpg"),
          name: "上门接货服务"
        },
        {
          banner: require("@/assets/valueAddedService/warehousing_services_03.jpg"),
          name: "仓储管理服务"
        },
        {
          banner: require("@/assets/valueAddedService/information_service_04.jpg"),
          name: "综合信息服务"
        },
        {
          banner: require("@/assets/valueAddedService/delivery_in_place_05.jpg"),
          name: "送货就位服务"
        },
        {
          banner: require("@/assets/valueAddedService/collection_agent_06.jpg"),
          name: "代收货款服务"
        },
        {
          banner: require("@/assets/valueAddedService/waiting_for_delivery_07.jpg"),
          name: "等通知放货服务"
        },
        {
          banner: require("@/assets/valueAddedService/sign_receipt_08.jpg"),
          name: "签收回单服务"
        },

      ],
      Main_business_name: [
        {
          name: "精准高快G",
          marginBottom: "74px",
          index: 0

        },
        {
          name: "精准特快T",
          marginBottom: "75px",
          index: 1
        },
        {
          name: "特惠快运K",
          marginBottom: "75px",
          index: 2
        },
        {
          name: "普惠班列P",
          index: 3
        },
      ],
      aboutUsList: [
        {
          img: require("@/assets/img/sincerity.png"),
          img_act: require("@/assets/img/sincerity_act.png"),
          index: 0,
          title: "诚信",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
        },
        {
          img: require("@/assets/img/quick .png"),
          img_act: require("@/assets/img/quick_act.png"),
          index: 1,
          title: "快捷",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
        {
          img: require("@/assets/img/security.png"),
          img_act: require("@/assets/img/security_act.png"),
          index: 2,
          title: "安全",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
        {
          img: require("@/assets/img/Efficient.png"),
          img_act: require("@/assets/img/Efficient_act.png"),
          index: 3,
          title: "高效",
          width: "222px",
          paddingRight: "36px",
          paddingLeft: "36px",
          borderLeft: '1px solid rgba(0, 0, 0, 0.1',
        },
      ],
      switchSwiper: 0,
      staffStyleIndex: '',
      swiper_circular: "0",
      scroll: '',
      show: true,
      aboutUsHover: null
    }
  },
  mounted () {
    this.getBanner(); //轮播
    window.addEventListener('scroll', this.menu)
  },
  methods: {
    see_more () {
      this.$router.push('/about-us')
    },
    menu () {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
    },
    bannerMouseover (index) {
      this.staffStyleIndex = index
    },
    bannerMouseout (index) {
      this.staffStyleIndex = ''
    },
    handlechange (index) {
      this.switchSwiper = index
      if (index === 0) {
        this.swiper_circular = "0"
      } else if (index === 1) {
        this.swiper_circular = "97px"
      } else if (index === 2) {
        this.swiper_circular = "194px"
      } else if (index === 3) {
        this.swiper_circular = "291px"
      }
    },
    handleActive (index) {
      this.$refs.nop.setActiveItem(index)

    },
    // 鼠标移出
    handelLeave (a) {
      this.aboutUsHover = null
    },
    // 鼠标移入
    handelenter (index) {
      this.aboutUsHover = index
    },

    //封装轮播函数
    getBanner () {
      //调用延迟加载 $nextTick
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true, // 循环模式选项
          //   autoplay: true, //自动切换
          //   virtualTranslate: true,
          autoplay: {
            delay: 3000,
          },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination"
          }
        });

        var valueAddedServices = new Swiper(".valueAddedServices", {
          autoplay: false,//等同于以下设置
          //   拖动时指针会变成抓手形状
          grabCursor: true,
          //   设置slider容器能够同时显示的slides数量(carousel模式)。
          slidesPerView: 4,
          // 在loop模式下使用slidesPerview:'auto'，还需使用该参数设置所要用到的loop个数(一般设置大于可视slide个数2个即可)。
          //   loopedSlides: 4,
          // 在slide之间设置距离（单位px）。
          //   spaceBetween: 20,
          // 在carousel mode下定义slides的数量多少为一组。
          slidesPerGroup: 1,
          //设置为true 则开启loop模式。loop模式：会在原本slide前后复制若干个slide(默认一个)并在合适的时候切换，让Swiper看起来是循环的。 
          // loop模式在与free模式同用时会产生抖动，因为free模式下没有复制slide的时间点。
          //   loop: true,
          // 在loop模式下，为group填充空白slide
          // loopFillGroupWithBlank: true,
          // 使用分页器导航
          // pagination: {
          //   el: '.swiper-pagination',
          //   // 此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
          //   clickable: true,
          // },
          // 使用前进后退按钮来控制Swiper切换。
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },

          observer: true,
          observeParents: true,

        });
      });
    },


  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "DIN-Condensed";
  src: url(../assets/font/DIN-Condensed.ttf);
}
.home {
  //   position: relative;
  .content_wechat {
    position: fixed;
    bottom: 40px;
    z-index: 999;
    right: 0;
    .tel_bg {
      display: flex;
      height: 60px;
      background: rgba(0, 0, 0, 0.32);
      margin-top: 5px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      color: #fff;
      font-size: 14px;
      img {
        width: 30px;
        height: 30px;
        transform: translateY(15px);
        margin: 0 10px;
      }
      .tel_text {
        line-height: 30px;
      }
    }
  }
  .middle_wechat {
    width: 163px;
    height: 181px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.32);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    z-index: 999;
  }
}
.banner {
  width: 100%;
  height: 100%;
}
.swiper-container {
  width: 100%;
  //   height: 650px;
  background: #f0f2f5;
}
.aboutUs_bg {
  width: 100%;
  height: 57px;
  text-align: center;
  margin: 0 0 40px 0;
  .aboutUs {
    transform: translateY(-50px);
    font-size: 24px;
    width: 100%;
  }
  .Underline {
    width: 30px;
    height: 4px;
    background: #734f21;
    margin: 9px auto;
  }
}
.aboutUsList_bg {
  display: flex;
  width: 1115px;
  margin: 41px auto 59px auto;
  justify-content: center;
  text-align: center;
  .aboutUsList:nth-child(1) {
    // background: rosybrown;
    border-right: rgba(0, 0, 0, 0.1);
  }
  .aboutUs_title_bg {
    width: 36px;
    margin: 0 auto;
    .aboutUs_title {
      color: #734f21;
    }
  }
}
.zx_introduce {
  display: flex;
  justify-content: center;
  img {
    width: 526px;
    height: 296px;
    margin-right: 41px;
  }
  .company_introduction {
    width: 710px;
  }
  .information_bg {
    .company_name {
      color: #734f21;
      font-size: 20px;
      margin-top: 19px;
      font-weight: 550;
    }
    .company_Pinyin {
      color: #000000;
      opacity: 0.25;
      margin-bottom: 30px;
    }
    .introduce {
      text-indent: 2em;
      font-weight: 100;
    }
    .introduction_information {
      font-size: 18px;
      //   color: #182d5d;
      line-height: 40px;
      font-weight: 100;
    }
    .see_more {
      background: #734f21;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      width: 80px;
      height: 32px;
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
// 主营业务
.Main_business_bag {
  height: 909px;
  color: #f8f8f8;
}
.Main_business_bg {
  background: url(../assets/img/mainBusiness.jpg) no-repeat;
  width: 100%;
  //   height: 577px;
  text-align: center;
  .Main_business_title {
    margin: 83px 0 33px 0;
  }
  .Main_business_swiper {
    display: flex;
    margin: 0 auto;
    width: 1208px;
    height: 661px;
    background: #f8f8f8;
    position: relative;
    .Main_business_move {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 72px;
      margin-left: -36px;
      z-index: 999;
      img {
        width: 14px !important;
        height: 14px !important;
        transform: translateY(2px);
      }
    }
    img {
      width: 881px;
      height: 523px;
    }
    .type_introduction_underline {
      width: 27px;
      height: 4px;
      background: #d19718;
      margin: 4px 0 12px 0;
    }
    // 滚动条
    .scroll_bar {
      color: #000;
      opacity: 0.8;
      display: flex;
      padding: 0 82px 0 50px;
      box-sizing: border-box;
      .el-button.el-button--text {
        padding: 0;
        color: #000;
        font-size: 18px;
      }
      .active {
        font-weight: bold;
      }
      .scroll {
        width: 6px;
        height: 305px;
        margin-right: 37px;
        background: #e7e7e7;
        position: relative;
        .scroll_circular {
          position: absolute;
          top: 0;
          left: -7px;
          width: 18px;
          height: 18px;
          padding: 8px;
          box-sizing: border-box;
          border-radius: 50%;
          background: #734f21;
          .scroll_circular2 {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: 1px;
            left: 1px;
            .scroll_circular3 {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #734f21;
              position: absolute;
              top: 3px;
              left: 3px;
            }
          }
        }
      }
    }
  }
  .main_business {
    // width: 881px;
    // height: 523px;
    margin-top: 68px;
    position: relative;
    .type_introduction_bg {
      position: absolute;
      top: 0;
      width: 881px;
      height: 523px;
      padding: 353px 56px 0 58px;
      box-sizing: border-box;
      text-align: left;
      opacity: 0;
      transition: opacity 0.5s;
      background-image: linear-gradient(
        to top,
        rgba(117, 85, 49, 0.8) 0%,
        rgba(117, 85, 49, 0.5) 50%,
        rgba(117, 85, 49, 0.2) 100%
      );

      .type_introduction {
        color: rgba(255, 255, 255, 0.85);
        line-height: 30px;
        font-weight: 100;
        .title {
          font-weight: 550;
        }
      }
    }
  }
  .main_business_banner:hover {
    .type_introduction_bg {
      opacity: 1;
    }
  }

  ::v-deep .el-carousel__container {
    width: 881px;
    height: 523px;
  }
}
.increment_bg {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 82px;
  box-sizing: border-box;
  .img_bg {
    width: 280px;
    height: 260px;
    overflow: hidden;
    margin: 0 auto;
    .picture_enlargement {
      transform: scale(1.1);
      margin-bottom: 15px;
    }
  }

  .staffStyle_name {
    width: 280px;
    margin: 0 auto;
    color: #734f21;
    border-bottom: 1px solid #734f21;
  }
  .swiper-father {
    position: relative;
    width: 1300px;
    margin: 0 auto;
    .toggle_arrow {
      .swiper-button-next,
      .swiper-button-prev {
        width: 25px;
        height: 54px;
      }
      .swiper-button-next {
        background: url("../assets/img/right.png");
        background-size: 25px 54px;
      }
      .swiper-button-prev {
        background: url("../assets/img/left.png");
        background-size: 25px 54px;
      }
    }
  }
  .valueAddedServices {
    width: 1200px;
    margin: 0 auto;
    height: 340px;
    position: relative;
    overflow: hidden;
    .swiper-slide {
      //   width: 300px !important;
      margin-right: 0 !important;
      .name {
        margin-top: 10px;
      }
    }
    img {
      overflow: hidden;
    }
  }
  .increment_move {
    font-size: 14px;
    color: #000000;
    opacity: 0.85;
    margin-bottom: 88px;
    margin-top: 20px;
  }
  .increment_move::after {
    content: "";
    display: inline-block;
    width: 13.75px;
    height: 13.75px;
    background: url(../assets/img/move.png);
    transform: translateY(2px);
  }
  .move {
    transform: translateY(2px);
    width: 13.75px;
    height: 13.75px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import 'swiper/js/swiper.min'
// import 'swiper/css/swiper.min.css'
import VueCountUp from 'vue-countupjs'
Vue.use(VueCountUp)

Vue.use(ElementUI);

document.title = "驮龙物流"
// 减少import引入
import assembly from './components/index'
Vue.use(assembly)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// 跳转页面滚动条回到顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
    export default {
      data() {
        return{
          h5_home:"https://kp.tuolong56.com/h5-home-page/index.html#/",
        }
      },
      mounted(){
        let is_mobile = this.on_is_mobile();
           if(is_mobile){
               window.location.href=this.h5_home;
           }
        window.onresize = () => {
           let is_mobile = this.on_is_mobile();
           if(is_mobile){
               window.location.href=this.h5_home;
           }
        }
      },
      methods:{
        on_is_mobile(){
          let is = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          if (is) {
              return true;
          } else {
             return false;
          }
        }
      }
    }
</script>
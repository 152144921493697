<template>
  <!-- <div> -->
  <div class="writtenWords_bg" :class="setClass ? 'setwrittenWords' : ''">
    <div :class="writtenWords_img ? 'writtenWords_img' : ''"></div>
    <img :src="img" alt="" />
    <div class="writtenWords_item">
      <div class="service_support">{{ title }}</div>
      <div class="service_support_underline"></div>
      <ul class="writtenWords">
        <li
          :style="{ marginRight: item.marginRight }"
          v-for="(item, index) in writtenWordsList"
          :key="index"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'textCommon',
  props: {
    title: {
      type: String
    },
    img: {
      type: String
    },
    setClass: {
      type: Boolean,
      default: false
    },
    writtenWords_img: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      writtenWordsList: [
        {
          title: "诚信",
          marginRight: '31px',
        }, {
          title: "安全",
          marginRight: '31px',
        }, {
          title: "高效",
          marginRight: '31px',
        }, {
          title: "快捷",
        },
      ]
    }
  }


}
</script>

<style lang="less" scoped>
.setwrittenWords {
  background: #f8f8f8;
}
.writtenWords_bg {
  //   background: #f0f2f5;
  position: relative;
  .writtenWords_img {
    position: absolute;
    top: 0;
    bottom: 4px;
    width: 100%;
    // height: 100%;
    background: rgba(115, 79, 33, 0.5);
  }
  img {
    width: 100%;
  }
  position: relative;
  text-align: left;
  color: #fff;
  .writtenWords_item {
    position: absolute;
    left: 50%;
    top: 50%;
    // top: 142px;
    width: 210px;
    margin-left: -105px;
    margin-top: -38px;
    z-index: 9999;

    .writtenWords {
      display: flex;
      font-size: 14px;
      opacity: 0.85;
      margin-top: 7px;
    }
    .service_support {
      font-size: 30px;
      border-bottom: 1px solid #fff;
      margin-bottom: 2px;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
    .service_support_underline {
      width: 124px;
      height: 1px;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>